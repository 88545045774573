<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon @click="activateUser" v-bind="attrs" v-on="on">
        <v-icon small v-text="statusData.name" :color="statusData.color" />
      </v-btn>
    </template>
    <span>{{ $t(statusData.tooltip) }}</span>
  </v-tooltip>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "UserActivation",

  props: {
    user: {
      type: Object,
      required: true,
      default: () => ({
        isActive: false,
      }),
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async activateUser() {
      try {
        await this.$axios.userManagement.activateUser({
          userId: this.user.id,
          isActive: !this.user.isActive,
        });

        this.notify({
          type: "success",
          message: this.statusData.success,
        });

        this.$emit("reload");
      } catch {
        this.notify({
          type: "error",
          message: this.statusData.error,
        });
      }
    },
  },

  computed: {
    isActive() {
      return this.user.isActive;
    },

    statusData() {
      if (this.isActive) {
        return {
          name: "fas fa-check-circle",
          color: "success",
          tooltip: "USER_MANAGEMENT.USERS.ACTIVATION.TOOLTIP",
          success: "USER_MANAGEMENT.USERS.DEACTIVATION.SUCCESS",
          error: "USER_MANAGEMENT.USERS.DEACTIVATION.ERROR",
        };
      }

      return {
        name: "fas fa-times-circle",
        color: "error",
        tooltip: "USER_MANAGEMENT.USERS.DEACTIVATION.TOOLTIP",
        success: "USER_MANAGEMENT.USERS.ACTIVATION.SUCCESS",
        error: "USER_MANAGEMENT.USERS.ACTIVATION.ERROR",
      };
    },
  },
};
</script>
