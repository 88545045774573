<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon @click="editUser" v-bind="attrs" v-on="on">
        <v-icon small v-text="`fas fa-theater-masks`" />
      </v-btn>
    </template>
    <span>{{ $t("USER_MANAGEMENT.USERS.ASSIGN.QUICK_ACTIONS.ASSIGN_ROLES") }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "AssignRoles",

  props: {
    userId: {
      type: Number,
      required: true,
    },
  },

  methods: {
    editUser() {
      this.$router.push({
        name: "ManagementUserAssignRoles",
        params: { userId: this.userId },
      });
    },
  },
};
</script>
