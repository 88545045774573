<template>
  <v-row no-gutters justify="end">
    <v-col
      cols="auto"
      :class="iconMargin"
      align-self="center"
      v-if="hasPermission({ permissionNames: ['user.update'] })"
    >
      <UserActivation :user="user" @reload="$emit('reload')" />
    </v-col>

    <v-col
      cols="auto"
      :class="iconMargin"
      align-self="center"
      v-if="hasPermission({ permissionNames: ['user.delete'] })"
    >
      <DeleteUser :userId="user.id" @reload="$emit('reload')" />
    </v-col>

    <v-col
      cols="auto"
      :class="iconMargin"
      align-self="center"
      v-if="hasPermission({ permissionNames: ['user.update'] })"
    >
      <AssignRoles :userId="user.id" />
    </v-col>
  </v-row>
</template>

<script>
import DeleteUser from "@views/Restricted/UserManagement/User/Delete";
import AssignRoles from "./AssignRoles.vue";
import UserActivation from "./UserActivation.vue";
import hasPermission from "@mixins/hasPermission";

export default {
  name: "QuickActions",

  mixins: [hasPermission],

  components: {
    AssignRoles,
    DeleteUser,
    UserActivation,
  },

  computed: {
    iconMargin() {
      return "mr-4 mr-md-0";
    },
  },

  props: {
    user: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
};
</script>
