<template>
  <div>
    <div v-if="showRoles">
      <div>
        {{ firstRole }}
        <span v-if="hasMultipleRoles"> ({{ $t("USER_MANAGEMENT.USERS.LIST.ROLES_MORE") }}) </span>
      </div>
    </div>
    <span v-else>{{ $t("USER_MANAGEMENT.USERS.LIST.ROLES_NONE") }}</span>
  </div>
</template>

<script>
import { first, isEmpty } from "lodash";
export default {
  name: "UserRoles",

  props: {
    roles: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  computed: {
    showRoles() {
      return !isEmpty(this.roles);
    },

    firstRole() {
      return first(this.roles).name;
    },

    hasMultipleRoles() {
      return this.roles.length > 1;
    },
  },
};
</script>
