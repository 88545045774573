<template>
  <ConfirmationDialog
    v-model="dialog"
    icon="far fa-trash"
    title="USER_MANAGEMENT.USERS.DELETE.DIALOG.TITLE"
    @confirm="deleteUser"
    tooltip="USER_MANAGEMENT.USERS.DELETE.DIALOG.TOOLTIP"
    confirmLabel="USER_MANAGEMENT.USERS.DELETE.DIALOG.BTN_LABEL"
    :loading="isLoading"
    body="USER_MANAGEMENT.USERS.DELETE.DIALOG.CONTENT"
  />
</template>

<script>
import ConfirmationDialog from "@components/ConfirmationDialog/";
import { mapActions } from "vuex";

export default {
  name: "UserDelete",

  components: {
    ConfirmationDialog,
  },

  data() {
    return {
      dialog: false,
      isLoading: false,
    };
  },

  props: {
    userId: {
      type: Number,
      required: true,
      default: null,
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async deleteUser() {
      this.isLoading = true;

      try {
        await this.$axios.userManagement.deleteUser({ userId: this.userId });
        this.$emit("reload");
        this.notify({
          type: "success",
          message: "USER_MANAGEMENT.USERS.DELETE.MESSAGE.SUCCESS",
        });
      } catch (err) {
        console.error(err);
        this.notify({
          type: "error",
          message: "USER_MANAGEMENT.USERS.DELETE.MESSAGE.ERROR",
        });
      } finally {
        this.dialog = false;
        this.isLoading = false;
      }
    },
  },
};
</script>
